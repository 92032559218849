import React, { useState, useEffect } from 'react'

import FormGroup from '@mui/material/FormGroup'
import * as Yup from 'yup'

import { Formik, Field, Form, ErrorMessage } from 'formik'

import Spinner from '../spinner/Spinner'
import { Row, Col, Label, Button } from 'reactstrap'

// eslint-disable-next-line import/no-anonymous-default-export
function Users({ initialValues = {}, handleSubmit }) {
  const [isLoading, setLoading] = useState(true)

  const [data, setPrepared] = useState(initialValues)

  useEffect(() => {
    setLoading(false)
  }, [])

  if (isLoading) return <Spinner />
  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        id: data?.id,
        name: data?.name,
        email: data?.email,
      }}
      validationSchema={Yup.object().shape({
        id: Yup.number(),
        name: Yup.string().required('Nome em falta.'),
        email: Yup.string()
          .email('Email incorreto.')
          .required('Email em falta.'),
      })}
      onSubmit={(values, { setStatus, setSubmitting }) => {
        setSubmitting(true)
        handleSubmit(values)
      }}
      render={({
        errors,
        status,
        touched,
        dirty,
        isValid,
        setFieldValue,
        setValues,
        setTouched,
        values,
        isSubmitting,
        resetForm,
        validateForm,
      }) => {
        if (isSubmitting) return <Spinner />
        return (
          <Form>
            <Row>
              <Col md={12}>
                <h5 className="my-3" style={{ fontWeight: 'bold' }}>
                  Utilizador
                </h5>
              </Col>
              <Col md={12}>
                <FormGroup className="mb-2">
                  <Label>Nome*</Label>
                  <Field
                    name="name"
                    placeholder=""
                    type="text"
                    disabled={isSubmitting}
                    className={
                      'form-control' +
                      (errors.name && touched.name ? ' is-invalid' : '')
                    }
                  />
                  <ErrorMessage
                    name="name"
                    component="div"
                    className="invalid-feedback"
                  />
                </FormGroup>
              </Col>
              <Col md={12}>
                <FormGroup className="mb-2">
                  <Label>Email*</Label>
                  <Field
                    name="email"
                    placeholder=""
                    type="text"
                    disabled={isSubmitting}
                    className={
                      'form-control' +
                      (errors.email && touched.email ? ' is-invalid' : '')
                    }
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="invalid-feedback"
                  />
                </FormGroup>
              </Col>
            </Row>
            <Button
              color="primary"
              disabled={!(isValid && dirty)}
              className="mr-2 button-cira"
            >
              Submeter
            </Button>
            <Button
              color="primary"
              onClick={() => resetForm()}
              disabled={!dirty}
              className="button-cira"
            >
              Limpar
            </Button>
          </Form>
        )
      }}
    />
  )
}

export default Users
