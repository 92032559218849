import React from 'react'
import { Input } from 'reactstrap'
import { useAsyncDebounce } from 'react-table'

function CustomFilter({ label, setFilter, id, global = false }) {
  const [value, setValue] = React.useState('')
  const onChange = useAsyncDebounce((value) => {
    if (global) return setFilter(value || undefined)
    setFilter(id, value || undefined)
  }, 200)

  return (
    <div className="mt-2">
      <Input
        name="id"
        placeholder={label}
        type="text"
        value={value}
        className="form-control"
        onChange={(e) => {
          setValue(e.target.value)
          onChange(e.target.value)
        }}
      />
    </div>
  )
}

export default CustomFilter
