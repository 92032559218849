import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  Button,
  Row,
  Col,
  Card,
  CardBody,
  Table,
  Badge,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap'
import { useTable, useFilters, usePagination } from 'react-table'
import InfoOutlined from '@mui/icons-material/InfoOutlined'
import CustomFilter from '../../layouts/layout-components/forms/CustomFilter'
import Select from 'react-select'

import Spinner from '../spinner/Spinner'
import { messageAlert } from '../../redux/users/Action'
import { Api } from '../../jwt/_services/OldApi'
import DynamicApi from '../../jwt/_helpers/DynamicApi'
import Users from '../forms/Users'

function UsersAdmin({ Utilizadores }) {
  const [isLoading, setLoading] = useState(false)
  const [isEditting, setEditting] = useState(false)
  const [listaFreguesias, setFreguesias] = useState([])
  const [listaConcelho, setConcelho] = useState([])
  const [listaTipos, setTipos] = useState([])
  const [isOpen, setOpen] = useState(null)
  const [forceUpdate, setUpdate] = useState(Date.now())
  const [isDeleting, setDelete] = useState(false)
  const [isRecover, setRecover] = useState(false)

  const columns = React.useMemo(
    () => [
      {
        Header: 'Nome',
        accessor: 'name',
      },
      {
        Header: 'Email',
        accessor: 'email',
      },
      {
        Header: 'Ativo',
        accessor: 'active',
      },
      {
        Header: 'Ações',
        accessor: 'actions',
        sortable: false,
        filterable: false,
      },
    ],
    []
  )

  useEffect(() => {
    const fetchData = async () => {
      const data = await DynamicApi('/api/admin/users', 'GET', null, true)
      setData(data)
    }

    fetchData()
  }, [forceUpdate])

  const handleDelete = () => {
    try {
      DynamicApi('/api/admin/users/' + isOpen.iduser, 'DELETE', null, true)
        .then((result) => {
          setUpdate(Date.now())
          setDelete(false)
        })
        .catch((err) =>
          dispatch(
            messageAlert(
              'danger',
              'Utilizador tem dados associados na plataforma.'
            )
          )
        )
    } catch (err) {
      dispatch(messageAlert('danger', err.message))
    }
  }

  const handleRecover = () => {
    try {
      DynamicApi('/api/admin/users/recover/' + isOpen.email, 'GET', null, true)
        .then((result) => {
          setUpdate(Date.now())
          dispatch(messageAlert('success', 'Email enviado com sucesso!'))
          setRecover(false)
        })
        .catch((err) =>
          dispatch(messageAlert('danger', 'Impossivel submeter o pedido.'))
        )
    } catch (err) {
      dispatch(messageAlert('danger', err.message))
    }
  }

  const dispatch = useDispatch()
  const [data, setData] = useState([])

  const setSelectRow = (values, isDelete = false) => {
    if (isDelete) setDelete(true)
    else setEditting(true)
    setOpen(values)
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    setFilter,
    rows,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    { columns, data, initialState: { pageIndex: 0 } },
    useFilters,
    usePagination
  )
  if (isLoading) return <Spinner />
  return (
    <>
      <Col sm={12} md={12}>
        <Row>
          <Col sm={3}>
            <Card className="p-2">
              <div className="mt-2 pt-2">
                <Label for="exampleCustomSelect">Filtrar Utilizadores</Label>
                {columns
                  .filter((elem) => elem.accessor !== 'actions')
                  .map((elem) => (
                    <CustomFilter
                      key={elem.accessor}
                      label={elem.Header}
                      setFilter={setFilter}
                      id={elem.accessor}
                    />
                  ))}
              </div>
            </Card>
          </Col>
          <Col sm={9}>
            <Card>
              <CardBody>
                <h4 className="card-title pb-2">Utilizadores</h4>
                {!isEditting && data.length === 0 && (
                  <div
                    className="flex flex-column items-center my-4"
                    style={{ fontSize: '10rem' }}
                  >
                    <InfoOutlined className="h3" fontSize="inherit" />
                    <h3>Sem Utilizadores disponíveis!</h3>
                  </div>
                )}
                {!isEditting && data.length > 0 && (
                  <Table bordered striped hover responsive {...getTableProps()}>
                    <thead>
                      {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                          {headerGroup.headers.map((column) => (
                            <th
                              // Return an array of prop objects and react-table will merge them appropriately
                              {...column.getHeaderProps()}
                            >
                              {column.render('Header')}
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                      {page.map((row, i) => {
                        prepareRow(row)
                        return (
                          // Merge user row props in
                          <tr {...row.getRowProps()}>
                            {row.cells.map((cell) => {
                              if (cell.column.id === 'actions')
                                return (
                                  <td
                                    // Return an array of prop objects and react-table will merge them appropriately
                                    {...cell.getCellProps()}
                                    onClick={(e) => e.stopPropagation()}
                                    className="px-1"
                                  >
                                    <Button
                                      className="rounded-full button-cira mdi mdi-email p-1 mx-1 line-height"
                                      title="Editar"
                                      onClick={() => {
                                        setSelectRow(row.original)
                                        setRecover(true)
                                      }}
                                    ></Button>
                                    <Button
                                      onClick={() => {
                                        setSelectRow(row.original, true)
                                      }}
                                      className="rounded-full button-cira ti-trash  p-1 line-height"
                                      title="Apagar"
                                    ></Button>
                                  </td>
                                )
                              if (cell.column.id == 'validation')
                                return (
                                  <td
                                    // Return an array of prop objects and react-table will merge them appropriately
                                    {...cell.getCellProps()}
                                  >
                                    <Badge
                                      color={
                                        cell.value === true
                                          ? 'warning'
                                          : 'success'
                                      }
                                      pill
                                    >
                                      {cell.value === true
                                        ? 'A Validar..'
                                        : 'Ativo'}
                                    </Badge>
                                  </td>
                                )
                              return (
                                <td
                                  // Return an array of prop objects and react-table will merge them appropriately
                                  {...cell.getCellProps()}
                                >
                                  {cell.render('Cell')}
                                </td>
                              )
                            })}
                          </tr>
                        )
                      })}
                    </tbody>
                  </Table>
                )}
                {!isEditting && (
                  <div className="pagination items-center">
                    <Button
                      color="primary"
                      className="mr-2 button-cira"
                      onClick={() => gotoPage(0)}
                      disabled={!canPreviousPage}
                    >
                      {'<<'}
                    </Button>{' '}
                    <Button
                      color="primary"
                      className="mr-2 button-cira"
                      onClick={() => previousPage()}
                      disabled={!canPreviousPage}
                    >
                      {'<'}
                    </Button>{' '}
                    <Button
                      color="primary"
                      className="mr-2 button-cira"
                      onClick={() => nextPage()}
                      disabled={!canNextPage}
                    >
                      {'>'}
                    </Button>{' '}
                    <Button
                      color="primary"
                      className="mr-2 button-cira"
                      onClick={() => gotoPage(pageCount - 1)}
                      disabled={!canNextPage}
                    >
                      {'>>'}
                    </Button>{' '}
                    <span>
                      Página{' '}
                      <strong>
                        {pageIndex + 1} de {pageOptions.length}
                      </strong>{' '}
                    </span>
                  </div>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Col>
      <Modal
        isOpen={isDeleting}
        toggle={() => {
          setDelete(false)
          setOpen(null)
        }}
      >
        <ModalHeader
          toggle={() => {
            setDelete(false)
            setOpen(null)
          }}
        >
          Gestão de Utilizadores
        </ModalHeader>
        <ModalBody>Deseja apagar o Utilizador {isOpen?.email}?</ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => handleDelete(isOpen?.iduser)}>
            Apagar
          </Button>
          <Button
            color="secondary"
            onClick={() => {
              setDelete(false)
              setOpen(null)
            }}
          >
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>
      <Modal
        isOpen={isRecover}
        toggle={() => {
          setRecover(false)
          setOpen(null)
        }}
      >
        <ModalHeader
          toggle={() => {
            setRecover(false)
            setOpen(null)
          }}
        >
          Forçar Recuperação de Conta - Enviar email com nova password.
        </ModalHeader>
        <ModalBody>Deseja Recuperar Utilizador {isOpen?.email}?</ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => handleRecover(isOpen?.iduser)}>
            Enviar Email.
          </Button>
          <Button
            color="secondary"
            onClick={() => {
              setRecover(false)
              setOpen(null)
            }}
          >
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

export default UsersAdmin
