import { Switch } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Row, Card, CardBody, Col, Label, Button, Input } from 'reactstrap'
import DynamicApi from '../../jwt/_helpers/DynamicApi'
import FormikCheckbox from '../../layouts/layout-components/forms/FormikCheckbox'
import { messageAlert } from '../../redux/users/Action'
import UsersAdmin from './UsersAdmin'

function Administration() {
  const [listModules, setlistModules] = useState([])
  const [isLoading, setLoading] = useState(true)
  const [fileImport, setFile] = useState(null)
  const dispatch = useDispatch()
  useEffect(() => {
    const fetchData = async () => {
      try {
        const { modules } = await DynamicApi(
          '/api/admin/modules',
          'GET',
          false,
          true
        )
        if (modules) {
          setlistModules(modules)
        }
      } catch (err) {
        dispatch(messageAlert('danger', err.message))
      } finally {
        setLoading(false)
      }
    }
    fetchData()
  }, [])

  const handleChange = async (index, value) => {
    DynamicApi(
      '/api/admin/modules',
      'POST',
      { key: listModules[index].id, value },
      true
    ).then(
      (res) =>
        res.success &&
        setlistModules([
          ...listModules.slice(0, index),
          { ...listModules[index], active: value },
          ...listModules.slice(index + 1),
        ])
    )
  }

  const handleGetOcorrencias = () => {
    DynamicApi(`/api/admin/ocorrencias/csv`, 'GET', null, true)
      .then((data) =>
        window.open(
          `${process.env.REACT_APP_APIURL}/api/protected/assets/csv?fsName=${data.fsName}&token=${data.token}`
        )
      )
      .catch((err) => dispatch(messageAlert('danger', err.message)))
  }

  const handleSubmit = () => {
    try {
      let formData = new FormData()
      formData.append('file', fileImport)
      setLoading(true)
      DynamicApi(
        '/api/admin/ocorrencias/csv',
        'POST',
        formData,
        true,
        true
      ).then((db) => {
        dispatch(
          messageAlert(
            'success',
            `CSV processado com sucesso! Espere ${db.eta} mins, enquanto as coordenadas estão a ser calculadas para ${db.calcNeed} ocorrências.`
          )
        )
      })
    } catch (err) {
      dispatch(messageAlert('danger', err.message))
    } finally {
      setFile(null)
      setLoading(false)
    }
  }

  return (
    <Row>
      <Col sm={12}>
        <Card>
          <span className="lstick"></span>
          <CardBody className="relative">
            <h4 className="card-title pb-2">Administração</h4>
            <div className="mt-2 pt-4 border-top flex justify-center">
              <h6>Módulos</h6>
            </div>
            <Row>
              {listModules.map((elem, index) => (
                <Col md={12}>
                  <div className="flex justify-between">
                    <Label>{elem.descr}</Label>
                    <Switch
                      checked={elem.active}
                      onChange={(e) => handleChange(index, e.target.checked)}
                    />
                  </div>
                </Col>
              ))}
            </Row>
            <div className="mt-2 pt-4 border-top flex justify-center">
              <h6>Importação de Ocorrências</h6>
            </div>
            <div className="flex justify-center">
              <Button
                color="primary"
                className="ml-4 button-cira"
                onClick={handleGetOcorrencias}
              >
                Download Ficheiro Importação de Ocorrências
              </Button>
            </div>
            <Row>
              <Col md={10}>
                <div className="flex flex-row items-center">
                  <Input
                    name="fileImport"
                    placeholder={'Ficheiro CSV'}
                    type="file"
                    className="form-control"
                    onChange={(event) => {
                      setFile(event.currentTarget.files[0])
                    }}
                  />
                  <Button
                    color="primary"
                    className="ml-2 button-cira inline-flex"
                    onClick={handleSubmit}
                  >
                    Importar CSV
                  </Button>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>

      <UsersAdmin />
    </Row>
  )
}

export default Administration
